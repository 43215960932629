<template>
  <div>
    <div class="form-content">
      <h1 class="">Password Recovery</h1>
      <p class="signup-link">
        Enter your email and instructions will be sent to you!
      </p>
      <form class="text-left" @submit.prevent="resetPassword()">
        <div class="form">
          <div id="email-field" class="field-wrapper input">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-at-sign"
            >
              <circle cx="12" cy="12" r="4"></circle>
              <path d="M16 8v5a3 3 0 0 0 6 0v-1a10 10 0 1 0-3.92 7.94"></path>
            </svg>
            <input
              id="email"
              name="email"
              type="email"
              v-model="email"
              placeholder="Email"
            />
          </div>
          <div class="d-sm-flex justify-content-between">
            <div class="field-wrapper">
              <button
                type="submit"
                class="btn btn-primary"
                value=""
                :disabled="loading"
              >
                <b-spinner small v-if="loading"></b-spinner>
                <span v-else>Reset</span>
              </button>
            </div>
          </div>
          <div class="field-wrapper mt-5 pt-5">
            <router-link to="/login" class="forgot-pass-link"
              >Go Back</router-link
            >
          </div>
        </div>
      </form>

      <div class="text-center">
        <p class="mt-5 pt-5 small">© 2021. Gerocare Solutions LTD.</p>
      </div>
    </div>
  </div>
</template>

<script>
import { http } from "../../utils/http";
import { endpoints } from "../../utils/endpoints";

export default {
  name: "Reset",

  data() {
    return {
      email: "",
      loading: false,
    };
  },

  mounted() {},

  methods: {
    resetPassword() {
      "";
      if (this.email.length < 8) {
        this.$toast.error("Please enter a valid email address!");
      } else {
        this.loading = true;
        http
          .post(endpoints.RESET_PASSWORD, {
            email: this.email,
          })
          .then((response) => {
            this.loading = false;
            this.$toast.success(response);
            setTimeout(() => {
              this.$router.push("/login");
            }, 4500);
          })
          .catch((error) => {
            this.loading = false;
            this.$toast.error(
              error.response?.data?.message ||
                error.response?.message ||
                error.response?.data
            );
          });
      }
    },
  },
};
</script>
